import styles from "@/styles/header.module.scss"
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router';
import Image from "next/image"
import Link from 'next/link'

import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const imageDomain = publicRuntimeConfig.imageDomain

export default function Header() {
  const router = useRouter();
  const [currentMenu, setCurrentMenu] = useState(0)

  const menuList = [
    {
      id: 0,
      name: '首页',
      link: '/'
    },
    {
      id: 1,
      name: '关于我们',
      link: '/about-us'
    },
    {
      id: 2,
      name: '企业文化',
      link: '/corporate-culture'
    },
    {
      id: 3,
      name: '业务发展',
      link: '/business-development'
    },
    {
      id: 4,
      name: '公司动态',
      link: '/company-news'
    },
    {
      id: 5,
      name: '人才招聘',
      link: '/careers'
    },
    {
      id: 6,
      name: '联系我们',
      link: '/supplier-cooperation'
    }
  ];

  useEffect(() => {
    // Find the menu item that matches the current route
    const currentPath = router.pathname;
    const currentItem = menuList.find((item) => item.link === currentPath);

    // Set the currentMenu to the found item's id
    if (currentItem) {
      setCurrentMenu(currentItem.id);
    }
  }, [router.pathname]);

  return (
    <div className={styles['comp-header']}>
      <div className={styles['container']}>
        <Link
          href={'/'}
          legacyBehavior
        >
          <a
            rel="noopener noreferrer"
          >
            <Image
              className={styles['logo']}
              alt={'logo'}
              width={154}
              height={42}
              src={`${imageDomain}/logo.png`}
            ></Image>
          </a>
        </Link>

        <div className={styles['menu-box']}>

          {
            menuList.map(item => (
              <div className={styles['menu-item']} key={item.id}>
                <Link
                  href={item.link}
                  legacyBehavior
                >
                  <a
                    className={`
                      ${styles['menu-name']}
                      ${currentMenu == item.id ? styles['menu-name-active'] : ''}
                    `}
                    rel="noopener noreferrer"
                  >
                    {item.name}
                  </a>
                </Link>

                <div className={`${currentMenu == item.id ? styles['active-sign'] : ''}`}></div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}