import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import { FloatButton } from 'antd'

interface LayoutProps {
  children: React.ReactNode;
}

export default function DefaultLayout(props: LayoutProps) {

  return (
    <div>
      <main style={{ background: '#FFFFFF', position: 'relative' }}>
        <Header></Header>
        {props.children}
      </main>

      <Footer></Footer>

      <FloatButton.BackTop />
    </div>
  )
}