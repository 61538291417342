import styles from "@/styles/footer.module.scss"
import Image from "next/image"
import Link from 'next/link'

import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const imageDomain = publicRuntimeConfig.imageDomain

export default function Footer() {

  return (
    <div className={styles['comp-footer']}>
      <div className={styles['container']}>
        <Link
          href={'/'}
          legacyBehavior
        >
          <a 
            rel="noopener noreferrer"
          >
            <Image 
              className={styles['logo']}
              alt={'logo'}
              width={154}
              height={42}
              src={`${imageDomain}/logo.png`}
            ></Image>
          </a>
        </Link>

        <div className={styles['related-box']}>
          <div className={`${styles['related-item']} padding-right-40`}>
            <div className={styles['item-title']}>了解我们</div>

            <Link
              href="/about-us"
              legacyBehavior
            >
              <a className={styles['item-desc']} rel="noopener noreferrer">公司简介</a>
            </Link>

            <Link
              href="/company-news"
              legacyBehavior
            >
              <a className={styles['item-desc']} rel="noopener noreferrer">CS动态</a>
            </Link>

            <Link
              href="/careers"
              legacyBehavior
            >
              <a className={styles['item-desc']} rel="noopener noreferrer">加入我们</a>
            </Link>
          </div>

          <div
            className={`${styles['related-item']} padding-right-80`}
          >
            <div className={styles['item-title']}>联系我们</div>
            <div className={styles['item-desc']}>联系电话：</div>
            <div className={styles['item-desc']}>020-34331422</div>
          </div>

          <div className={styles['related-item']}>
            <Image 
              className={styles['qr-code']}
              alt={'qr-code'}
              width={107}
              height={107}
              src={`${imageDomain}/qr-code.png`}
            ></Image>
            <div 
              className={styles['item-desc']}
              style={{ textAlign: 'center' }}
            >扫一扫·关注我们</div>
          </div>
        </div>

      </div>

      <div className={styles['copy-right']}>
        Copyright &copy; 2003 - 2024 杰钡利科技. All rights reserved. 杰钡利科技 版权所有
        <Link
          href="https://beian.miit.gov.cn/#/Integrated/index"
          legacyBehavior
        >
          <a rel="noopener noreferrer"> 粤ICP备18049355号-1</a>
        </Link>
      </div>

      <div className={styles['copy-right']} style={{ paddingTop: '12px' }}>
        <Link
          href="https://beian.mps.gov.cn/#/query/webSearch?code=44010502003088"
          legacyBehavior
        >
          <a style={{ display: 'flex', alignItems: 'center', fontSize: '18px' }} rel="noopener noreferrer" target="_blank">
            <Image 
              style={{ marginLeft: 10, marginRight: 5 }}
              alt={'record-keeping'}
              width={18}
              height={20}
              src={`${imageDomain}/record-keeping.png`}
            ></Image>
            粤公网安备44010502003088
          </a>
        </Link>
      </div>
    </div>
  )
}