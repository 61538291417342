import Head from "next/head"
import styles from "@/styles/home.module.scss"
import DefaultLayout from "@/components/DefaultLayout"
import ObserverSection from '@/components/ObserverSection'
import { useState, useEffect } from "react"
import CountUp from 'react-countup'
import Image from "next/image"
import { Image as AntImage } from 'antd'
import Link from 'next/link'

import {
  EnvironmentFilled
} from '@ant-design/icons';

import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const imageDomain = publicRuntimeConfig.imageDomain

export default function Home() {
  const [currentCore, setCurrentCore] = useState(0)
  const [currentBanner, setCurrentBanner] = useState(1)


  const handleAdd = () => {
    for (let i = 1; i <= 7; i++) {
      setTimeout(() => {
        const icon = document.getElementById(`location-icon-${i}`);
        if (icon) {
          // 先把 opacity 从 0 调整为 1
          icon.style.opacity = '1';
          // 然后添加动画类名
          icon.classList.add('animate__animated', 'animate__fadeInUp');
        }
      }, i * 250); // 每个图标的延迟时间增加 0.25s
    }
  };

  const handleRemove = () => {
    for (let i = 1; i <= 7; i++) {
      const icon = document.getElementById(`location-icon-${i}`);
      console.log(icon);
      if (icon) {
        // 先把 opacity 从 1 调整为 0
        icon.style.opacity = '0';
        // 然后移除动画类名
        icon.classList.remove('animate__animated', 'animate__fadeInUp');
      }
    }
  };

  useEffect(() => {
    const bannerInterval = setInterval(() => {
      setCurrentBanner(currentBanner + 1)
    }, 6000);
    return () => clearInterval(bannerInterval);
  }, [currentBanner])

  return (
    <>
      <Head>
        <title>
          CrazySales® Official Site - Cheap Online Shopping Australia Store.
        </title>

        <meta
          name="keywords"
          content="Online Shopping Australia, online shopping sites, Home Shopping, Shopping Deals, Best Deals, Home Garden, Pet Supplies, Sports Fitness, Bedding Bath, Buy Online, Discount Shopping, Toys Hobbies, Beauty Health, Crazysales, Computers, IT, Christmas, Electronics, Automotive, Tools, Appliances, Baby, CrazySales"
        />
        <meta
          name="description"
          content="Australia's #1 online shopping website! Cheap bargains & discounts with the top online shopping Australia store! From furniture, to toys & hobbies, to appliances & pet supplies -- we've got it all, with free shipping on selected items!"
        />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
      </Head>

      <DefaultLayout>
        <main className={styles["page-index"]}>
          <div className={styles["banner-box"]}>
            {
              currentBanner%2 === 0
              ?
              <Image 
                className={styles['banner']}
                alt={'banner'}
                width={1920}
                height={639}
                priority={true}
                src={`${imageDomain}/home/banner2.png`}
              ></Image>
              :
              <Image 
                className={styles['banner']}
                alt={'banner'}
                width={1920}
                height={639}
                priority={true}
                src={`${imageDomain}/home/banner.png`}
              ></Image>
            }
            
            
            <div className={styles['title-box']}>
              <AntImage src={`${imageDomain}/home/logo.png`} alt={'logo'} preview={false} />
              {/* <div className={styles['title']}>Crazysales</div>
              <div className={styles['sub-title']}>打破世界信息差  共享全球优质产品</div> */}
            </div>
          </div>

          <div className={styles['about-us']}>
            <div className={styles['text-content']}>
              <ObserverSection customClassName="fade-in">
                <div className={styles['english-title']}>About Us</div>
                <div className={styles['title']}>关于我们</div>

                <div className={styles['desc']} style={{ width: '360px' }}>广州杰钡利科技致力成为一家不断迭代、多元发展的全球化企业。<br/>2003年诞生于澳洲专注做进出口跨境电商，是跨境电商的开拓者。广州杰钡利科技一直坚守“打破世界信息差，共享全球优质产品”的使命，深耕跨境电商领域，实现公司及人才的双丰收。</div>
              </ObserverSection>

              <ObserverSection
                customClassName={styles['location-list-static']} 
                onAdd={handleAdd}
                onRemove={handleRemove}
              >
                <div className={styles['location-list']}>
                  {Array.from({ length: 7 }, (_, index) => (
                    <div className={`${styles['location-icon']} ${styles[`location-${index + 1}`]}`}>
                      <Image
                        id={`location-icon-${index + 1}`}
                        key={index}
                        className={`${styles['location-icon-image']}`}
                        alt={'location-icon'}
                        width={20}
                        height={28}
                        src={`${imageDomain}/home/location-icon.png`}
                      />
                    </div>
                  ))}
                </div>
              </ObserverSection>
            </div>

            <div className={styles['about-us-bg-box']}>
              <Image 
                className={styles['about-us-bg']}
                alt={'about-us-bg'}
                width={1048}
                height={524}
                priority={true}
                src={`${imageDomain}/home/about-us-bg-no-location.png`}
              ></Image>
            </div>
          </div>

          <div className={styles['core-business-mode']}>
            <Image 
              className={styles['core-business-mode-bg']}
              alt={'business-mode-bg'}
              width={1048}
              height={524}
              src={`${imageDomain}/home/core-business-bg.png`}
            ></Image>

            <div className={styles['text-content']}>
              <ObserverSection customClassName="fade-in">
                <div className={styles['english-title']}>Core Business Model</div>
                <div className={styles['title']}>核心经营模式</div>

                <div className={styles['desc']}>广州杰钡利科技拥有产品自主开发能力，擅长多品类、多平台经营，开发优质商品，满足客户<br/>多元化购物需求，致力将中国制造的优质产品带到全球各地。</div>
              </ObserverSection>
              
              <div className={styles['core-list']}>
                <div 
                  className={`
                    ${styles['core-item']} 
                    ${currentCore == 0 ? styles['active'] : ''}
                  `}
                  style={{ backgroundImage: `url(${imageDomain}/home/cross-border-export.png)` }}
                  onClick={() => {
                    setCurrentCore(0)
                  }}
                >
                  <ObserverSection customClassName="fade-in">
                    <div className={styles['title']}>
                      <div className={styles['title-text']}>
                        跨境电商零售<br/>头部企业
                      </div>
                      <div className={styles['icon-more']}>→</div>
                    </div>

                    <div className={styles['core-desc']}>主营产品涵盖生活家居、健身器材、家用电器、儿童玩具、3C科技、运动户外、宠物用品等全领域品类拓展；齐全的类目和丰富的产品为客户提供优质的购物体验。</div>
                  </ObserverSection>
                  <div className={styles['selling-points-list']}>
                    <div className={styles['points-item']}>
                      <CountUp 
                        className={styles['item-title']} 
                        end={17 as number} 
                        separator=","
                        suffix={'大品类'}
                        enableScrollSpy={true}
                       />
                      <div className={styles['item-sub-title']}>自主开发</div>
                    </div>
                    <div className={styles['points-item']} style={{ borderLeft: '1px solid #ffffff', borderRight: '1px solid #ffffff' }}>
                      <CountUp 
                        className={styles['item-title']} 
                        end={900 as number} 
                        separator=","
                        suffix={'+'}
                        enableScrollSpy={true}
                       />
                      <div className={styles['item-sub-title']}>细分品类</div>
                    </div>
                    <div className={styles['points-item']}>
                      <CountUp 
                        className={styles['item-title']} 
                        end={100000 as number} 
                        separator=","
                        suffix={'+'}
                        enableScrollSpy={true}
                       />
                      <div className={styles['item-sub-title']}>SKU</div>
                    </div>
                  </div>
                </div>

                <div 
                  className={`
                    ${styles['core-item']} 
                    ${currentCore == 1 ? styles['active'] : ''}
                  `} 
                  style={{ backgroundImage: `url(${imageDomain}/home/professional-team.png)` }}
                  onClick={() => {
                    setCurrentCore(1)
                  }}
                >
                  <ObserverSection customClassName="fade-in">
                    <div className={styles['title']}>
                      <div className={styles['title-text']}>
                        专业的团队
                      </div>
                      <div className={styles['icon-more']}>→</div>
                    </div>

                    <div className={styles['core-desc']}>二十多年的跨境电商行业经验，拥有专业的采购开发、运维、市场营销、IT研发及物流等团队，利用自研系统高效地解决了多品类、多供应商、多平台、多仓库、多国家的跨境交易与客户服务；同时，公司还组建专业健全的客服团队为客户提供优质贴心的售前售后服务。</div>
                  </ObserverSection>
                  <div className={styles['selling-points-list']} style={{ paddingLeft: '20px' }}>
                    <div className={styles['points-item']}>
                      <div className={styles['item-title']}>多供应商</div>
                    </div>
                    <div className={styles['points-item']} style={{ borderLeft: '1px solid #ffffff', borderRight: '1px solid #ffffff' }}>
                      <div className={styles['item-title']}>多平台</div>
                    </div>
                    <div className={styles['points-item']} style={{ borderRight: '1px solid #ffffff' }}>
                      <div className={styles['item-title']}>多仓库</div>
                    </div>
                    <div className={styles['points-item']}>
                      <div className={styles['item-title']}>多国家</div>
                    </div>
                  </div>
                </div>

                <div 
                  className={`
                    ${styles['core-item']} 
                    ${currentCore == 2 ? styles['active'] : ''}
                  `} 
                  style={{ backgroundImage: `url(${imageDomain}/home/complete-warehousing.png)` }}
                  onClick={() => {
                    setCurrentCore(2)
                  }}
                >
                  <ObserverSection customClassName="fade-in">
                    <div className={styles['title']}>
                      <div className={styles['title-text']}>
                        完善的仓储<br/>物流体系
                      </div>
                      <div className={styles['icon-more']}>→</div>
                    </div>

                    <div className={styles['core-desc']}>为了提升配送的质量和速度，集团搭建了海内外仓储物流系统，在澳洲、新西兰、美国等地均有自建仓库和第三方合作的仓储服务，实现货物本地配送服务。</div>
                  </ObserverSection>

                  <div className={styles['warehouse-list']}>
                    <div className={styles['warehouse-item']}>
                      <EnvironmentFilled />澳洲
                    </div>
                    <div className={styles['warehouse-item']}>
                      <EnvironmentFilled />新西兰
                    </div>
                    <div className={styles['warehouse-item']}>
                      <EnvironmentFilled />美国
                    </div>
                    {/* <div className={styles['warehouse-item']}>
                      <EnvironmentFilled />哥伦比亚
                    </div> */}
                  </div>
                </div>
              </div>

              <div className={styles['self-operated-platform']}>
                <div className={styles['platform-english-title']}>Self-Operated Platforms</div>
                <div className={styles['platform-title']}>自营平台</div>
                <div className={styles['platform-list']}>
                  <Image 
                    className={styles['platform-logo']}
                    alt={'crazysales-logo'}
                    width={214}
                    height={59}
                    src={`${imageDomain}/home/crazysales-logo.png`}
                  ></Image>
                  <Image 
                    className={styles['platform-logo']}
                    style={{ paddingLeft: '30px' }}
                    alt={'bd-logo'}
                    width={271}
                    height={61}
                    src={`${imageDomain}/home/bd-logo.png`}
                  ></Image>
                </div>
              </div>

              <div className={styles['self-operated-platform']}>
                <div className={styles['platform-english-title']}>Collaboration Platforms</div>
                <div className={styles['platform-title']}>合作平台</div>
                <div className={styles['platform-list']}>
                  <Image 
                    className={styles['platform-logo']}
                    alt={'ebay-logo'}
                    width={145}
                    height={56}
                    src={`${imageDomain}/home/ebay-logo.png`}
                  ></Image>
                  <Image 
                    className={styles['platform-logo']}
                    alt={'amazon-logo'}
                    width={160}
                    height={48}
                    src={`${imageDomain}/home/amazon-logo.png`}
                  ></Image>
                  <Image 
                    className={styles['platform-logo']}
                    alt={'catch-logo'}
                    width={131}
                    height={57}
                    src={`${imageDomain}/home/catch-logo.png`}
                  ></Image>
                  <Image 
                    className={styles['platform-logo']}
                    alt={'kogan-logo'}
                    width={171}
                    height={58}
                    src={`${imageDomain}/home/kogan-logo.png`}
                  ></Image>
                  <Image 
                    className={styles['platform-logo']}
                    alt={'mydeal-logo'}
                    width={202}
                    height={46}
                    src={`${imageDomain}/home/mydeal-logo.png`}
                  ></Image>
                </div>

                <div className={styles['platform-list']}>
                  <Image 
                    className={styles['platform-logo']}
                    alt={'until-gone-logo'}
                    width={113}
                    height={66}
                    src={`${imageDomain}/home/until-gone-logo.png`}
                  ></Image>
                  <Image 
                    className={styles['platform-logo']}
                    alt={'wool-worths-logo'}
                    width={224}
                    height={47}
                    src={`${imageDomain}/home/wool-worths-logo.png`}
                  ></Image>
                  <Image 
                    className={styles['platform-logo']}
                    alt={'bunnings-warehouse-logo'}
                    width={157}
                    height={49}
                    src={`${imageDomain}/home/bunnings-warehouse-logo.png`}
                  ></Image>
                  <Image 
                    className={styles['platform-logo']}
                    alt={'harvery-norman-logo'}
                    width={221}
                    height={31}
                    src={`${imageDomain}/home/harvery-norman-logo.png`}
                  ></Image>
                  <Image 
                    className={styles['platform-logo']}
                    alt={'my-sale-logo'}
                    width={194}
                    height={46}
                    src={`${imageDomain}/home/my-sale-logo.png`}
                  ></Image>
                </div>

                <div className={styles['platform-list']}>
                  <Image 
                    className={styles['platform-logo']}
                    alt={'grabone-logo'}
                    width={201}
                    height={37}
                    src={`${imageDomain}/home/grabone-logo.png`}
                  ></Image>
                  <Image 
                    className={styles['platform-logo']}
                    alt={'the-warehouse-logo'}
                    width={291}
                    height={52}
                    src={`${imageDomain}/home/the-warehouse-logo.png`}
                  ></Image>
                  <Image 
                    className={styles['platform-logo']}
                    alt={'trade-me-logo'}
                    width={282}
                    height={39}
                    src={`${imageDomain}/home/trade-me-logo.png`}
                  ></Image>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DefaultLayout>
    </>
  )
}