import { useEffect, useRef } from 'react';


interface ObserverOptions {
  customActiveClassName: string;
  onAdd?: () => void; // 当类名被添加时的回调函数
  onRemove?: () => void; // 当类名被移除时的回调函数
}

const useFadeInObserver = ({ customActiveClassName, onAdd, onRemove }: ObserverOptions) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(customActiveClassName);
            if (onAdd) {
              onAdd(); // 当类名添加时调用 onAdd 回调
            }
          } else {
            entry.target.classList.remove(customActiveClassName);
            if (onRemove) {
              onRemove(); // 当类名移除时调用 onRemove 回调
            }
          }
        });
      },
      { threshold: 0.2 }
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  return ref;
};

export default useFadeInObserver;
